import { ReactElement } from 'react';
import Script from 'next/script';
import getConfig from 'next/config';

import { GtmCode } from '@hubcms/domain-tracking';

type GTMHeadProps = {
  gtmCodes: GtmCode[];
};

export function GTMHead({ gtmCodes }: GTMHeadProps): ReactElement | null {
  if (!gtmCodes.length) return null;

  const { publicRuntimeConfig } = getConfig();

  const gtmString = gtmCodes
    .map(gtmCode => {
      const searchParams = new URLSearchParams();

      const isParentGtmContainer = gtmCode.isParent;

      const gtmAuth: string | undefined = isParentGtmContainer
        ? publicRuntimeConfig.GTM_AUTH_PARENT_CONTAINER
        : publicRuntimeConfig.GTM_AUTH_CHILD_CONTAINER;
      const gtmPreview: string | undefined = isParentGtmContainer
        ? publicRuntimeConfig.GTM_PREVIEW_PARENT_CONTAINER
        : publicRuntimeConfig.GTM_PREVIEW_CHILD_CONTAINER;

      if (gtmAuth && gtmPreview) {
        searchParams.set('gtm_auth', gtmAuth);
        searchParams.set('gtm_preview', gtmPreview);
        searchParams.set('gtm_cookies_win', 'x');
      }

      const gtmEnvParams = searchParams.has('gtm_auth') ? `&${searchParams.toString()}` : '';

      return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+'${gtmEnvParams}';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmCode.code}');`;
    })
    .join('');

  return (
    <Script
      id="gtm"
      dangerouslySetInnerHTML={{
        __html: gtmString,
      }}
    />
  );
}
