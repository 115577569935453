import { useEffect, useMemo } from 'react';
import { authMethod } from '@hubcms/brand';
import { useAuth } from '@hubcms/data-access-auth';
import { useEnv } from '@hubcms/data-access-env';
import { useZephrData } from '@hubcms/data-access-zephr';
import { CookData } from '@hubcms/domain-cook';
import { sendTrackingEvent } from '@hubcms/data-access-tracking';
import { usePrevious } from '@hubcms/utils-tracking';

import { createPageViewParams } from '../utils/createPageViewParams';
import { getArticleParams } from '../utils/getArticleParams';
import { getPageParams } from '../utils/getPageParams';
import { getUserParams } from '../utils/getUserParams';
import { PageParams } from '../domain/page-params';
import { PaywallParams } from '../domain/paywall-params';
import { getRecommendationParams } from '../utils/getRecommendationParams';
import { getPageSubType } from '../utils/getPageSubType';
import { getPaywallParams } from '../utils/getPaywallParams';
import { isSamePageViewParams } from '../utils/isSamePageViewParams';

type TrackPageViewProps = {
  brandCode: string;
  pagetitle: string;
  cookData?: CookData;
  pagetype?: PageParams['pagetype'];
  pagesecure?: PageParams['pagesecure'];
  pagesecuretype?: PageParams['pagesecuretype'];
  pagesectiontrees?: PageParams['pagesectiontrees'];
  pagesectionname?: PageParams['pagesectionname'];
  accessloginshown?: PaywallParams['accessloginshown'];
  accesswalltype?: PaywallParams['accesswalltype'];
};

export function TrackPageView({
  brandCode,
  pagetitle,
  cookData,
  pagetype,
  pagesecure,
  pagesecuretype,
  pagesectiontrees,
  pagesectionname,
  accessloginshown,
  accesswalltype,
}: TrackPageViewProps): null {
  const env = useEnv();
  const { user, isLoading: isLoadingAuth } = useAuth();
  const { subscriber, paywallConfig, reason, result } = useZephrData();

  const pageParams = useMemo(() => getPageParams(brandCode, pagetitle, cookData), [brandCode, pagetitle, cookData]);
  const articleParams = useMemo(() => (cookData ? getArticleParams(cookData) : {}), [cookData]);
  const pageSubType = cookData ? getPageSubType(cookData) : null;
  const userParams = useMemo(() => {
    if (isLoadingAuth) {
      return null;
    }
    return getUserParams({ user, allowedProducts: subscriber?.allowedProducts ?? [], authMethod, pageSubType });
  }, [user, subscriber?.allowedProducts, pageSubType, isLoadingAuth]);

  const paywallParams = useMemo(() => getPaywallParams(paywallConfig, reason, result), [paywallConfig, reason, result]);
  const recommendationParams = useMemo(() => getRecommendationParams(), []);
  /**
   * overrideParams are later spread together w/ other params
   * As spreading a prop with a value of undefined overwrites the previously defined
   * value for that prop, we want to avoid that by only setting the props necessary
   */
  const overrideParams = useMemo(
    () => ({
      ...(pagetype !== undefined ? { pagetype } : null),
      ...(pagesecure !== undefined ? { pagesecure } : null),
      ...(pagesecuretype !== undefined ? { pagesecuretype } : null),
      ...(pagesectiontrees !== undefined ? { pagesectiontrees } : null),
      ...(pagesectionname !== undefined ? { pagesectionname } : null),
      ...(accessloginshown !== undefined ? { accessloginshown } : null),
      ...(accesswalltype !== undefined ? { accesswalltype } : null),
    }),
    [pagetype, pagesecure, pagesecuretype, pagesectiontrees, pagesectionname, accessloginshown, accesswalltype],
  );

  const pageViewParams = useMemo(() => {
    if (userParams) {
      return createPageViewParams(
        pageParams,
        userParams,
        paywallParams,
        articleParams,
        recommendationParams,
        env,
        brandCode,
        overrideParams,
      );
    }
    return null;
  }, [pageParams, userParams, articleParams, paywallParams, env, brandCode, overrideParams, recommendationParams]);

  const previousPageViewParams = usePrevious(pageViewParams);

  useEffect(() => {
    if (typeof window !== 'undefined' && pageViewParams && !isSamePageViewParams(pageViewParams, previousPageViewParams)) {
      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(() => {
        sendTrackingEvent(pageViewParams);
      });
    }
  }, [pageViewParams, previousPageViewParams]);

  return null;
}
