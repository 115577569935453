import type { PaywallConfig, PaywallData } from '@hubcms/domain-zephr';

import type { PaywallParams } from '../domain/paywall-params';
import { getAccessWalltypeFallback } from './getAccessWalltypeFallback';
import { getAccessloginshownFallback } from './getAccessloginshownFallback';
import { extractAccessloginshownFromTrackingData } from './extractAccessloginshownFromTrackingData';
import { extractAccesswalltypeFromTrackingData } from './extractAccesswalltypeFromTrackingData';

export function getPaywallParams(
  paywallConfig: PaywallConfig | null,
  reason: string | null,
  result: PaywallData['result'] | null,
): Partial<PaywallParams> {
  if (result && result.outcomes) {
    const firstOutcome = result.outcomes.filter(candidate => !!candidate.showTrackingData).at(0);
    if (firstOutcome && firstOutcome.showTrackingData) {
      const trackingData = JSON.parse(firstOutcome.showTrackingData);
      const accesswalltype = extractAccesswalltypeFromTrackingData(trackingData);
      const accessloginshown = extractAccessloginshownFromTrackingData(trackingData);
      return {
        ...(accessloginshown !== null ? { accessloginshown } : null),
        ...(accesswalltype !== null ? { accesswalltype } : null),
      };
    }
  }

  return {
    accessloginshown: getAccessloginshownFallback(result, paywallConfig),
    accesswalltype: paywallConfig?.tracking?.labelPrefix ?? getAccessWalltypeFallback(reason),
  };
}
